import axios from 'axios';
// import env from 'react-dotenv';


//  console.log('env.REACT_APP_SERVER_URL_BASE', process.env.REACT_APP_SERVER_URL_BASE)

//const SERVER_URL_BASE = 'https://tranquil-ravine-05971-1b814f30e6c3.herokuapp.com' // process.env.REACT_APP_SERVER_URL_BASE;
// const SERVER_URL_BASE = 'http://localhost:5000'
const SERVER_URL_BASE = 'https://wealth-assessment-api.certaintyapp.com'

console.log('SERVER_URL_BASE', SERVER_URL_BASE)

// set token if it's in localStorage
let token;

if (localStorage.getItem('auth')) {
  token = JSON.parse(localStorage.getItem('auth'))?.token;
}

let configFromLocalStorage = null;
if (token) {
  configFromLocalStorage = {
    headers: {
      "x-auth-token": token
    }
  }
}

const BASEURL = SERVER_URL_BASE;

const questionsURL = `${BASEURL}/questions/`;
const answersURL = `${BASEURL}/answers/`;
const authURL = `${BASEURL}/auth/`;
const logInURL = `${BASEURL}/auth/login/`;
const usersURL = `${BASEURL}/users/`;
const wealthTypesURL = `${BASEURL}/wealthtypes/`;
const settingsURL = `${BASEURL}/settings/`;
const googleSheetURL = `${BASEURL}/apis/googlesheet/`;
const activeCampaignURL = `${BASEURL}/apis/activecampaign/`;
const dropoffURL = `${BASEURL}/apis/dropoff/`;
const getContactInfo = `${BASEURL}/apis/contact/`;



// Users

export const logIn = (logInData) => axios.post(logInURL, logInData);

export const fetchLoggedInUser = (userId) => axios.get(`${usersURL}/${userId}`);

export const updateUser = (userId, userParams, config = configFromLocalStorage) => axios.patch(`${usersURL}/${userId}`, userParams, config)

export const createUser = (userParams) => axios.post(usersURL, userParams);

// Questions

export const fetchQuestions = () => {
  return axios.get(questionsURL);
}

export const createQuestion = (newQuestion, userToken = token) => {
  return axios.post(questionsURL, newQuestion, {
    headers: {
      "x-auth-token": userToken
    }
  });
}

export const fetchQuestion = (questionId) => {
  return axios.get(`${questionsURL}/${questionId}`);
}

export const updateQuestion = (questionId, questionParams, userToken = token) => {
  return axios.patch(`${questionsURL}/${questionId}`, questionParams, {
    headers: {
      "x-auth-token": userToken
    }
  })
}

export const deleteQuestion = (questionId, userToken = token) => {
  return axios.delete(`${questionsURL}/${questionId}`, {
    headers: {
      "x-auth-token": userToken
    }
  });
}

export const getAnswersForQuestionWithId = (questionId, userToken = token) => {
  return axios.get(`${questionsURL}/${questionId}/answers`)
}

export const resortQuestions = (questions, userToken = token) => {
  return axios.post(`${questionsURL}/resort`, questions, {
    headers: {
      "x-auth-token": userToken
    }
  });
}

// Answers

export const fetchAnswers = () => {
  return axios.get(answersURL);
}

export const createAnswer = (newAnswer, userToken = token) => {
  return axios.post(answersURL, newAnswer, {
    headers: {
      "x-auth-token": userToken
    }
  });
}

export const fetchAnswer = (answerId) => {
  return axios.get(`${answersURL}/${answerId}`);
}

export const updateAnswer = (answerId, answerParams, userToken = token) => {
  console.log('answerId', answerId)
  console.log('answerParams', answerParams)
  return axios.patch(`${answersURL}/${answerId}`, answerParams, {
    headers: {
      "x-auth-token": userToken
    }
  })
}

export const deleteAnswer = (answerId, userToken = token) => {
  return axios.delete(`${answersURL}/${answerId}`, {
    headers: {
      "x-auth-token": userToken
    }
  });
}

// Wealth Types

export const fetchWealthTypes = () => {
  return axios.get(wealthTypesURL);
}

export const createWealthType = (newWealthType, userToken = token) => {

  return axios.post(wealthTypesURL, newWealthType, {
    headers: {
      "x-auth-token": userToken
    }
  });
}

export const fetchWealthType = (wealthTypeId) => {
  return axios.get(`${wealthTypesURL}/${wealthTypeId}`);
}

export const updateWealthType = (wealthTypeId, wealthTypeParams, userToken = token) => {
  return axios.put(`${wealthTypesURL}/${wealthTypeId}`, wealthTypeParams, {
    headers: {
      "x-auth-token": userToken
    }
  })
}

export const deleteWealthType = (wealthTypeId, userToken = token) => {
  return axios.delete(`${wealthTypesURL}/${wealthTypeId}`, {
    headers: {
      "x-auth-token": userToken
    }
  });
}

// Settings

export const fetchSettings = () => {
  return axios.get(settingsURL);
}

export const createSetting = (newSettings, userToken = token) => {

  return axios.post(settingsURL, newSettings, {
    headers: {
      "x-auth-token": userToken
    }
  });
}

export const updateSetting = (settingId, settingsParams, userToken = token) => {
  return axios.patch(`${settingsURL}/${settingId}`, settingsParams, {
    headers: {
      "x-auth-token": userToken
    }
  })
}

export const deleteSetting = (settingId, userToken = token) => {
  return axios.delete(`${settingsURL}/${settingId}`, {
    headers: {
      "x-auth-token": userToken
    }
  });
}

// Submissions
export const createSubmission = async (submissionDetails, userToken = token) => {
  try {
    const googleSheetResponse = await axios.post(googleSheetURL, submissionDetails, {
      headers: {
        "x-auth-token": userToken
      }
    });

    const activeCampaignResponse = await axios.post(activeCampaignURL, submissionDetails.activeCampaignObject, {
      headers: {
        "x-auth-token": userToken
      }
    })
    return { googleSheetResponse, activeCampaignResponse }

  }

  catch (error) {
    console.log('error', error)
    return
  }

  return "activeCampaignResponse"
}


// Drop off

export const sendDropOff = async (dropOffData, userToken = token) => {
  return axios.post(dropoffURL, dropOffData, {
    headers: {
      "x-auth-token": userToken
    }
  });
}

// Change password and email
export const changePassword = async (passwordData, userToken = token) => {
  return axios.post(`${authURL}/change-password`, passwordData, {
    headers: {
      "x-auth-token": userToken
    }
  });
}

export const changeEmail = async (emailData, userToken = token) => {
  return axios.post(`${authURL}/change-email`, emailData, {
    headers: {
      "x-auth-token": userToken
    }
  })
}


export const getAcTags = async (userToken = token) => {
  return axios.get(`${BASEURL}/apis/tags`, {
    headers: {
      "x-auth-token": userToken
    }
  })
}

export const getAcFields = async (userToken = token) => {
  return axios.get(`${BASEURL}/apis/fields`, {
    headers: {
      "x-auth-token": userToken
    }
  })
}

export const getAcContacts = async (userToken = token) => {
  const data = await axios.get(`${BASEURL}/apis/contacts`, {
    headers: {
      "x-auth-token": userToken
    }
  });

  console.log('data-contacts', data)
  return data.data;
}