import * as api from '../api';
import { FETCH_QUESTIONS, GET_ANSWERS_FOR_QUESION, FETCH_QUESTION, CREATE_QUESTION, DELETE_QUESTION, UPDATE_QUESTION, SET_QUESTION, FETCH_ANSWERS, SET_CURRENT_SELECTED_ANSWER, END_QUIZ, ADD_ANSWER_TO_SUBMISSION, QUESTIONS_RESORT, SET_REDIRECT_URL, BACK_QUESTION } from '../types/index';
import { addAnswerToSubmission } from './submissions';
export const getQuestions = () => async (dispatch) => {
  try {
    const { data } = await api.fetchQuestions();
    dispatch({
      type: FETCH_QUESTIONS,
      payload: data
    });
  } catch (error) {
    console.log(error.message);
  }
}

export const getQuestion = (questionId) => async (dispatch) => {
  try {
    const { data } = await api.fetchQuestion(questionId);
    dispatch({
      type: FETCH_QUESTION,
      payload: data
    })
  } catch (error) {
    console.log(error)
  }
}

export const createQuestion = (questionParams) => async (dispatch, getState) => {
  try {
    const { data } = await api.createQuestion(questionParams, getState().auth.token);
    dispatch({
      type: CREATE_QUESTION,
      payload: data
    });
  } catch (error) {
    console.log(error)
  }
}

export const updateQuestion = (questionId, questionParams) => async (dispatch, getState) => {
  try {
    const { data } = await api.updateQuestion(questionId, questionParams, getState().auth.token);
    dispatch({
      type: UPDATE_QUESTION,
      payload: data
    });
  } catch (error) {
    console.log(error);
  }
}

export const deleteQuestion = (questionId) => async (dispatch, getState) => {
  try {
    await api.deleteQuestion(questionId, getState().auth.token);
    dispatch({
      type: DELETE_QUESTION,
      payload: questionId
    });
  } catch (error) {
    console.log(error);
  }
}

export const answerQuestion = (question, answer) => async (dispatch, getState) => {

}


export const getAnswersForQuestionWithId = (questionId) => async (dispatch, getState) => {
  try {
    const { data } = await api.getAnswersForQuestionWithId(questionId, getState().auth.token);
    dispatch({
      type: GET_ANSWERS_FOR_QUESION,
      payload: data
    })
  } catch (error) {
    console.log(error.message)
  }
}

export const getQuestionWithAnswers = (questionId) => async (dispatch, getState) => {
  const questionData = await api.fetchQuestion(questionId);
  const answerData = await api.getAnswersForQuestionWithId(questionId);

  dispatch({
    type: SET_QUESTION,
    payload: questionData.data,
  });

  dispatch({
    type: GET_ANSWERS_FOR_QUESION,
    payload: answerData.data,
  })
}

export const getCurrentQuestionIndex = (question, questions) => {
  return questions.findIndex((el) => el._id === question._id)
}


////////////////////////
// Submission Actions //
////////////////////////

// TODO check this when adding the NULL type
const addUpWealthTypeScores = (answers) => {
  let score = {};

  answers.forEach(obj => {
    let wealthTypeName = obj.wealthTypeName;

    if (score[wealthTypeName]) {
      score[wealthTypeName] += 1;
    } else {
      score[wealthTypeName] = 1;
    }
  });
  return score;
}

// Loop through the scores object and find the highest value's wealthTypeName
const getHighestValueWealthTypeName = (objectOfScores) => {
  let maxKey = null;
  let maxValue = -Infinity;

  for (const key in objectOfScores) {
    if (key === 'NULL') continue;
    if (objectOfScores.hasOwnProperty(key)) {
      if (objectOfScores[key] > maxValue) {
        maxValue = objectOfScores[key];
        maxKey = key;
      }
    }
  }

  return maxKey;
}


const compileSubmissionData = (answers) => {
  console.log('aswers-custom', answers)

  // Init output object
  let output = {
    googleArray: [],
    activeCampaignObject: {
      firstName: '',
      lastName: '',
      specialFields: [],
      tag: 'hustler',
    },
    wealthTypeName: '',
  }

  const getCurrentDate = () => {
    const currentDate = new Date();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }

  // Get Date
  const date = getCurrentDate()
  output.googleArray.push(date)

  // Add name
  const subName = answers.find((el) => el.specialTag === 'name');
  // Push full name to Google Sheet
  output.googleArray.push(subName?.value);

  // Split name (first and last) and add to Active Campaign. If no last name use 'NONE'
  if (subName?.value.includes(' ')) {
    output.activeCampaignObject['firstName'] = subName?.value.split(' ')[0];
    output.activeCampaignObject['lastName'] = subName?.value.split(' ')[1];
  } else {
    output.activeCampaignObject['firstName'] = subName?.value;
    output.activeCampaignObject['lastName'] = 'NONE';
  }

  // Email
  const subEmail = answers.find((el) => el.specialTag === 'email');
  output.googleArray.push(subEmail?.value);
  output.activeCampaignObject['email'] = subEmail?.value;

  // Loops through all non-special answers and adds them to the activeCampaignObject.specialFields array
  // answers.forEach((ans) => {
  //   if (ans.specialTag === 'name' || ans.specialTag === 'email') return;
  //   if (ans.specialTag) {
  //     console.log('specialTag for special answer', ans)
  //     // this data looks like this { body: ..., questionId: ..., specialTag: "20", value: "retail" }
  //     // specialTag corrlates to the custom field ID in Active Campaign
  //     output.activeCampaignObject.specialFields.push([ans.specialTag, ans.value]);
  //   }
  // });

  // Get special Answers and add to Google Array
  // Find answers with a specialTag
  const specialAnswers = answers.filter((el) => el.specialTag !== undefined && el.specialTag !== "");
  console.log('specialAnswers-custom', specialAnswers)
  specialAnswers.forEach((ans) => {
    if (ans.specialTag === 'name' || ans.specialTag === 'email') {
      return;
      // name and email are already in the array, nothing to add here
      // the below is adding the name and email again, no need to do this, but it's working now
    } else {
      console.log('ans', ans);
      console.log('here--------')
      output.googleArray.push(ans.value || ans.body);
      output.activeCampaignObject.specialFields.push([ans.specialTag, ans.body]);

    }
    // if (ans.specialTag === 'name') {
    //   output.activeCampaignObject['name'] = ans.value;
    // } else if (ans.specialTag === 'email') {
    //   output.activeCampaignObject['email'] = ans.value;
    // } else {
  });

  ;
  // Get Regular Answers and add to Google Array
  const regularAnswers = answers.filter((el) => !el.specialTag);

  regularAnswers.forEach((ans) => {
    output.googleArray.push(ans.body);
  });


  const scores = addUpWealthTypeScores(regularAnswers);
  const wealthType = getHighestValueWealthTypeName(scores);

  output.activeCampaignObject['tag'] = wealthType;
  output.wealthTypeName = wealthType;
  output.googleArray.push(wealthType);
  output.googleArray.push(scores["Hustler"] || "0")
  output.googleArray.push(scores["Gambler"] || "0")
  output.googleArray.push(scores["Optimizer"] || "0")
  output.googleArray.push(scores["Saver"] || "0")
  output.googleArray.push(scores["Avoider"] || "0")

  console.log('regularAnswers', regularAnswers)
  console.log('Wealth Type', wealthType);
  console.log('specialAnswers', specialAnswers)
  console.log('scores', scores)
  console.log('output', output)
  return output;
}

export const advanceQuestion = (currentQuestion, questions, selectedAnswer) => async (dispatch, getState) => {
  let currentIndex = getCurrentQuestionIndex(currentQuestion, questions);
  addAnswerToSubmission(selectedAnswer);

  // Add the selected answer to the all the answers
  const answersBeforeThisQuestion = getState().submission.answers;
  const updatedAnswers = [...answersBeforeThisQuestion, selectedAnswer]

  // Set next question
  let nextQuestion = questions[currentIndex + 1];
  // If no next question, end the quiz
  if (!nextQuestion) {
    // Compile the submission data
    const finalSubmissionData = compileSubmissionData(updatedAnswers);
    // Check if there are two types of wealth type
    if (finalSubmissionData?.wealthTypeName.includes(',')) {
      console.log('there are two types of wealt type and I" splitting them so we can get the proper url')
      finalSubmissionData.wealthTypeName = finalSubmissionData.wealthTypeName.split(',')[0];
    }

    console.log('should set rediret url', finalSubmissionData.wealthTypeName)
    dispatch({
      type: SET_REDIRECT_URL,
      payload: finalSubmissionData.wealthTypeName,
    })

    // Send the submission data to the server
    await api.createSubmission(finalSubmissionData);
    console.log('submitted')


    // dispatch({
    //   type: END_QUIZ,
    //   payload: finalSubmissionData.wealthTypeName,
    // })

    return;

  }

  // If there is a next question, get the answers for that question and set the correct states in the store
  const answerData = await api.getAnswersForQuestionWithId(nextQuestion._id);

  dispatch({
    type: ADD_ANSWER_TO_SUBMISSION,
    payload: selectedAnswer,
  })

  dispatch({
    type: SET_QUESTION,
    payload: nextQuestion,
  });

  dispatch({
    type: GET_ANSWERS_FOR_QUESION,
    payload: answerData.data,
  })

  dispatch({
    type: SET_CURRENT_SELECTED_ANSWER,
    payload: "",
  })
}

// Resort the questions
export const questionsResort = (questions) => async (dispatch, getState) => {
  const data = await api.resortQuestions(questions);
  dispatch({
    type: QUESTIONS_RESORT,
    payload: questions,
  })
}


function findIndexById(array, id) {
  for (let i = 0; i < array.length; i++) {
    if (array[i]._id === id) {
      return i;
    }
  }
  // If the id is not found, return -1
  return -1;
}


// Go back to the previous question (when using the back button)
export const goBackQuestion = () => async (dispatch, getState) => {
  const index = findIndexById(getState().questions, getState().question._id);
  console.log('index', index)

  const previousQuestion = getState().questions[index - 1];
  console.log('previousQuestion', previousQuestion);

  const answersArray = getState().submission.answers
  const previousAnswer = answersArray[answersArray.length - 1];
  console.log('previousAnswer', previousAnswer);

  const { data } = await api.getAnswersForQuestionWithId(previousQuestion._id, getState().auth.token);

  console.log('answrs with question', data)

  const submission = getState().submission;

  const updatedArray = submission.answers.slice(0, submission.answers.length - 1);
  console.log('updatedArray', updatedArray)

  dispatch({
    type: BACK_QUESTION,
    payload: { previousQuestion, previousAnswer, updatedArray },
  })

  dispatch({
    type: GET_ANSWERS_FOR_QUESION,
    payload: data,
  })
}